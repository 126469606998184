<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('MENU.data_access_configuration') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="card-body p-0">


          <div class="form-group row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('data_access_configuration.user') }}<span class="text-danger">*</span></label>
              <multiselect v-model="user"
                           :placeholder="$t('data_access_configuration.user')"
                           label="name"
                           track-by="id"
                           :options="users"
                           :multiple="false"
                           :class="validation && validation.user_id ? 'is-invalid' : ''"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           @input="setUser"
                           @search-change="getUsers($event)">
              </multiselect>
              <span v-if="validation && validation.user_id"
                    class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.user_id[0] }}
                                    </span>
            </div>

            <div class="col-lg-6  mb-5">
              <label>{{ $t('data_access_configuration.level') }}</label>
              <div class="input-group">
                <select name="" id="is_general" v-model="data.level" type="text" class="custom-select"
                        :class="validation && validation.level ? 'is-invalid' : ''">
                  <option v-for="(row, index) in levels" :value="row.id" :key="index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.level" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.level[0] }}
                  </span>
              </div>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('data_access_configuration.inventories') }}</label>
              <multiselect v-model="inventory"
                           :placeholder="$t('data_access_configuration.inventories')"
                           label="name"
                           track-by="id"
                           :options="inventories"
                           @input="getMultiSelectInventory"
                           :multiple="true"
                           :class="validation && validation.multi_inventories ? 'is-invalid' : ''"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
              <span v-if="validation && validation.multi_inventories"
                    class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.multi_inventories[0] }}
                                    </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('data_access_configuration.branches') }}</label>
              <multiselect v-model="branch"
                           :placeholder="$t('data_access_configuration.branches')"
                           label="name"
                           track-by="id"
                           :options="branches"
                           @input="getMultiSelectBranch"
                           :multiple="true"
                           :class="validation && validation.multi_branches ? 'is-invalid' : ''"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
              <span v-if="validation && validation.multi_branches"
                    class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.multi_branches[0] }}
                                    </span>
            </div>
            <div class="form-group col-md-6">
              <label for="category">{{ $t('data_access_configuration.categories') }}</label>
              <multiselect v-model="category"
                           :placeholder="$t('data_access_configuration.categories')"
                           label="name"
                           track-by="id"
                           :options="categories"
                           :class="validation && validation.categories ? 'is-invalid' : ''"
                           :multiple="true"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           @input="getMultiSelectCategory">
              </multiselect>
              <span v-if="validation && validation.categories" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.categories[0] }}
                                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('data_access_configuration.departments') }}</label>
              <multiselect v-model="department"
                           :placeholder="$t('data_access_configuration.departments')"
                           label="name"
                           track-by="id"
                           :options="departments"
                           :class="validation && validation.departments ? 'is-invalid' : ''"
                           :multiple="true"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           @input="getMultiSelectDepartment">
              </multiselect>
              <span v-if="validation && validation.departments" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.departments[0] }}
                                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('data_access_configuration.banks') }}</label>
              <multiselect v-model="bank"
                           :placeholder="$t('data_access_configuration.banks')"
                           label="name"
                           track-by="id"
                           :options="banks"
                           :multiple="true"
                           :class="validation && validation.banks ? 'is-invalid' : ''"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false"
                           @input="getMultiSelectBanks">
              </multiselect>
              <span v-if="validation && validation.banks" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.banks[0] }}
                                </span>
            </div>

          </div>

        </div>
        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="reset" class="btn btn-primary mr-2" @click="save()">
                {{ $t('save') }}
              </button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-user",
  data() {
    return {
      mainRoute: 'data_access_user',
      mainRouteDependency: 'base/dependency',
      data: {
        user_id: null,
        level: null,
        multi_inventories: [],
        multi_branches: [],
        categories: [],
        departments: [],
        banks: [],

      },
      validation: null,

      inventory: null,
      branch: null,
      user: null,
      bank: null,
      category: null,
      department: null,

      inventories: [],
      branches: [],
      users: [],
      banks: [],
      categories: [],
      departments: [],

      levels: [
        {id: '0', title: this.$t('data_access_configuration.special')},
        {id: '1', title: this.$t('data_access_configuration.general')},
        {id: '2', title: this.$t('data_access_configuration.custom')},
      ],

    };
  },
  watch: {},
  methods: {
    save() {
      this.create();
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.restData();
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    restData() {
      this.data = {
        user_id: null,
        level: null,
        multi_inventories: [],
        multi_branches: [],
        categories: [],
        departments: [],
        banks: [],
      };

      this.inventory = null;
      this.branch = null;
      this.user = null;
      this.bank = null;
      this.category = null;
      this.department = null;
    },

    async getData(id) {
      ApiService.get(`users/${id}`).then((response) => {
        this.data.level = response.data.data.level;

        this.data.multi_inventories = response.data.data.multi_inventories;
        this.inventory = this.inventories.filter((row) => response.data.data.multi_inventories.includes(row.id));

        this.data.multi_branches = response.data.data.multi_branches;
        this.branch = this.branches.filter((row) => response.data.data.multi_branches.includes(row.id));

        this.data.categories = response.data.data.categories;
        this.category = this.categories.filter((row) => response.data.data.categories.includes(row.id));

        this.data.departments = response.data.data.departments;
        this.department = this.departments.filter((row) => response.data.data.departments.includes(row.id));

        this.data.banks = response.data.data.banks;
        this.bank = this.banks.filter((row) => response.data.data.banks.includes(row.id));

        // this.data.categories = response.data.data.categories;
        // this.data.departments = response.data.data.departments;
        // this.data.banks = response.data.data.banks;

        // this.data.category = response.data.data.categories_list;
        // this.data.department = response.data.data.departments_list;
        // this.data.bank = response.data.data.banks_list;
      });
    },
    getMultiSelectInventory() {
      this.data.multi_inventories = [];
      this.inventory.filter((row) => {
        this.data.multi_inventories.push(row.id);
      });
    },
    setUser(event) {
      if (event) {
        this.data.user_id = event.id;
        this.getData(event.id);
      } else
        this.data.user_id = null;
    },
    getMultiSelectBranch() {
      this.data.multi_branches = [];
      this.branch.filter((row) => {
        this.data.multi_branches.push(row.id);
      });
    },

    getMultiSelectCategory() {
      this.data.categories = [];
      this.category.filter((row) => {
        this.data.categories.push(row.id);
      });
    },
    getMultiSelectDepartment() {
      this.data.departments = [];
      this.department.filter((row) => {
        this.data.departments.push(row.id);
      });
    },
    getMultiSelectBanks() {
      this.data.banks = [];
      this.bank.filter((row) => {
        this.data.banks.push(row.id);
      });
    },
    async getBranches() {
      await ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    async getInventories() {
      await ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    getBanks() {
        ApiService.get(`${this.mainRouteDependency}/banks`).then((response) => {
          this.banks = response.data.data;
        });

    },
    getDepartments() {
      // if (filter && filter.length >= 3), {params: {filter: filter}}
        ApiService.get(`${this.mainRouteDependency}/departments`).then((response) => {
          this.departments = response.data.data;
        });
    },
    getCategories() {
      ApiService.get('base/dependency/customer-category').then(response => {
        this.categories = response.data.data;
      })
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("MENU.data_access_configuration")},
      {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    this.getInventories();
    this.getBranches();
    this.getCategories();
    this.getDepartments();
    this.getBanks();


  },
};
</script>


